<!-- <template>
  <v-dialog v-model="modalData.dialog" width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Qualification
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="9" md="9">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  label="Qualification"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            v-if="
              (permissionCan('create') && this.modalData.editedIndex === -1) ||
              permissionCan('update')
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = {
  translations: {},
};

export default {
  name: "QualificationForm",
  props: ["modalData", "permissions", "loader"],
  data() {
    return {
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: initialFormData,
      formValid: false,
      nameRules: [
        (v) => !!v || "Qualification is required",
        (v) => v.length > 2 || "Qualification must be min 3 characters",
      ],
      snackbar: false,
    };
  },
  computed: {

    formTitle() {
      return this.modalData.editedIndex === -1
        ? "New Qualification"
        : "Edit Qualification";
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = value.editedItem;
        this.setTranslatedAttributes();
      },
    },
  },
  methods: {
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.snackbar = true;
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },
    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },
    handleNew() {
      this.setTranslatedAttributes(true);
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "contacts.qualification." + action
      );
    },
  },
  async mounted() {
    this.selectedLocale = i18nService.languages.find((item) => {
      return item.lang == i18nService.getActiveLanguage();
    });
  },
};
</script>
 -->




 <template>
  <v-dialog v-model="modalData.dialog" width="650px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New Qualification
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :key="selectedLocale.lang + '-name'"
                  label="Qualification"
                  :error-messages="
                    messages['translations.' + selectedLocale.lang + '.name']
                  "
                  @keyup="
                    messages['translations.' + selectedLocale.lang + '.name'] =
                      ''
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { ENDPOINT, PERMISSION_TO } from "./Qualifications";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = () => ({
  id: null,
  translations: {},
});

export default {
  name: "QualificationForm",
  props: ["modalData", "permissions"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) => v.length < 255 || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  computed: {
    formTitle() {
      return this.formModel.id ? "Edit qualification" : "New qualification";
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value.editedId) {
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());

          this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.snackbar = true;
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, data));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
  },

  async mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
