<!-- <template>
  <div data-app>
    <v-card>
      <v-card-title>
        Qualifications
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="qualificationCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <QualificationModalForm
              :loader="loader"
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
            ></QualificationModalForm>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="permissionCan('delete')"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import QualificationModalForm, {
  initialFormData,
} from "./QualificationModalForm";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const ENDPOINT = "contact/qualification/";

export default {
  name: "qualifications",
  components: { QualificationModalForm },
  data() {
    return {
      loader: false,
      showAll: false,
      search: "",
      collection: [],
      permissions: [],
      headers: [
        {
          text: "Qualification",
          value: "name",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedIndex: -1,
        editedItem: initialFormData,
      },

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters(["qualificationCollection"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    industryCollection(val) {
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions(["fetchQualification"]),
    editItem(id) {
      ApiService.get(ENDPOINT + id)
        .then(({ data }) => {
          this.modalData.editedIndex = 1;
          this.modalData.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.qualificationCollection.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchQualification();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      if (this.$route.name !== "qualifications")
        this.$router.push({ name: "qualifications" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      /**Delete unused translations */
      i18nService.languages.forEach((item) => {
        let langCode = item.lang;
        if (
          model.translations[langCode] &&
          model.translations[langCode].name == ""
        ) {
          delete model.translations[langCode];
        }
      });
      this.loader = true;
      if (this.modalData.editedIndex > -1) {
        ApiService.put(ENDPOINT + model.id, model)
          .then(() => {
            this.fetchQualification();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        //create model
        ApiService.post(ENDPOINT, model)
          .then(({ data }) => {
            this.handleClickItem(data);
            this.fetchQualification();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/contacts/qualifications/${item.id}`);
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "contacts.qualification." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("qualification") > -1
        );
      }
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Industry" }]);
    this.fetchQualification();
    this.setPermissions();
  },
};
</script> -->



<template>
  <div data-app>
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="qualificationCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
        :loading="loadingTable"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <QualificationModalForm
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></QualificationModalForm>
            <v-spacer></v-spacer>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import QualificationModalForm, {
  initialFormData,
} from "./QualificationModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

export const ENDPOINT = "contact/qualification/";

export const PERMISSION_TO = "contacts.qualification.";

export default {
  name: "Qualifications",
  components: { QualificationModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "qualifications",
      routePath: "/contacts/Qualifications/",
      permissionTo: PERMISSION_TO,

      cardTitle: "Qualifications",
      search: "",
      permissions: JSON.parse(localStorage.permissions),

      headers: [
        {
          text: "Qualification",
          value: "name",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,
    };
  },

  computed: {
    ...mapGetters(["qualificationCollection"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchQualification"]),
    fetchModel() {
      return this.fetchQualification();
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
